* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Jost', sans-serif !important;
  line-height: 1.6;
  background-color: #f8f9fa;
  color: #333;
}

.container {
  max-width: 1110px;
  margin: 0 auto;
  padding: 0 20px;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 15px;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: var(--hover);
}

.dtr-btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--secondary);
  color: #fff;
  transition: background-color 0.3s ease;
}

.dtr-btn:hover {
  background-color: #e67e22;
}

.navbar {
  padding: 15px 20px;
}

.dtr-responsive-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
